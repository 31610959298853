document.addEventListener('DOMContentLoaded', ev => {
    const themeToggleDarkIcon = document.getElementById('theme-toggle-dark-icon');
    const themeToggleLightIcon = document.getElementById('theme-toggle-light-icon');

    // Change the icons inside the button based on previous settings
    try {
        const darkModeToggle = document.getElementById("dark-mode");

        if (localStorage.getItem('color-theme') === 'dark' || (!('color-theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
            themeToggleLightIcon.classList.toggle('hidden');
        } else {
            if (darkModeToggle) {
                themeToggleDarkIcon.classList.toggle('hidden');
            }
        }
    } catch (e) {
        console.error(`다크모드 실행 중 에러: ${e}`);
    }

    const themeToggleBtn = document.getElementById('theme-toggle');

    let event = new Event('dark-mode');

    themeToggleBtn.addEventListener('click', function () {

        // if set via local storage previously
        if (localStorage.getItem('color-theme')) {
            if (localStorage.getItem('color-theme') === 'light') {
                document.documentElement.classList.add('dark');
                localStorage.setItem('color-theme', 'dark');
                // loadingTradingViewHeaderView()
            } else {
                document.documentElement.classList.remove('dark');
                localStorage.setItem('color-theme', 'light');
            }

            // if NOT set via local storage previously
        } else {
            if (document.documentElement.classList.contains('dark')) {
                document.documentElement.classList.remove('dark');
                localStorage.setItem('color-theme', 'light');
            } else {
                document.documentElement.classList.add('dark');
                localStorage.setItem('color-theme', 'dark');
            }
        }

        loadingTradingViewTickerTape(localStorage.getItem('color-theme'));
        loadingTradingViewHeaderView('UPBIT:BTCKRW', 'BINANCE:BTCUSDT', localStorage.getItem('color-theme'));
        loadingTradingViewChart('UPBIT:BTCKRW', localStorage.getItem('color-theme'));

        document.dispatchEvent(event);

    });

})


